const ActionType = {
    //common
    START_LOGIN: "START_LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",

    //ERROR
    SHOW_AUTH_ERROR_NOTI: "SHOW_AUTH_ERROR_NOTI",

    //sign in
    REMOVE_EMAIL_ERROR: "REMOVE_EMAIL_ERROR",
    REMOVE_PASSWORD_ERROR: "REMOVE_PASSWORD_ERROR",
    SIGNIN_FAIL: "SIGNIN_FAIL",

    //sign up
    REMOVE_EMAIL_SIGNUP_ERROR: "REMOVE_EMAIL_SIGNUP_ERROR",
    SIGNUP_FAIL: "SIGNUP_FAIL",

    //sign out
    LOGOUT: "LOGOUT",

    //get products
    START_GET_TOP_RATING: "START_GET_TOP_RATING",
    GET_TOP_RATING_SUCCESS: "GET_TOP_RATING_SUCCESS",
    GET_TOP_RATING_FAIL: "GET_TOP_RATING_FAIL",

    START_GET_LAPTOP_LIST: "START_GET_LAPTOP_LIST",
    GET_LAPTOP_LIST_SUCCESS: "GET_LAPTOP_LIST_SUCCESS",
    GET_LAPTOP_LIST_FAIL: "GET_LAPTOP_LIST_FAIL",

    START_GET_MONITOR_LIST: "START_GET_MONITOR_LIST",
    GET_MONITOR_LIST_SUCCESS: "GET_MONITOR_LIST_SUCCESS",
    GET_MONITOR_LIST_FAIL: "GET_MONITOR_LIST_FAIL",

    START_GET_CPU_LIST: "START_GET_CPU_LIST",
    GET_CPU_LIST_SUCCESS: "GET_CPU_LIST_SUCCESS",
    GET_CPU_LIST_FAIL: "GET_CPU_LIST_FAIL",

    //cart
    START_GET_CART_LIST: "START_GET_CART_LIST",
    GET_CART_LIST_SUCCESS: "GET_CART_LIST_SUCCESS",
    GET_CART_LIST_FAIL: "GET_CART_LIST_FAIL",

    GET_CART_QUANTITY: "GET_CART_QUANTITY",
    ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
    REMOVE_PRODUCT_FROM_CART: "REMOVE_PRODUCT_FROM_CART",
    REMOVE_ALL_CART: "REMOVE_ALL_CART",
    CHANGE_QUANTITY_PRODUCT: "CHANGE_QUANTITY_PRODUCT",

    SHOW_CART_NOTI: "SHOW_CART_NOTI",
    HIDE_CART_NOTI: "HIDE_CART_NOTI",

    CLEAR_CART_UI: "CLEAR_CART_UI",

    //favorite
    SHOW_ADD_FAVORITE_NOTI: "SHOW_ADD_FAVORITE_NOTI",
    SHOW_REMOVE_FAVORITE_NOTI: "SHOW_REMOVE_FAVORITE_NOTI",

    HIDE_FAVORITE_NOTI: "HIDE_FAVORITE_NOTI",
}

export default ActionType;

